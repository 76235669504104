import tinymce from 'tinymce/tinymce';
import { logger } from '@root/utilities/logger';
import { Application } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { clearTurboFrame } from '@root/utilities/utils';
import debounce from 'debounce';
import BaseController from './base_controller';
import { errorHandler } from '../utilities/error_tracking';
import 'tinymce/models/dom/model';
import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';

import register from '../editor_controllers/register';
import {
  campaign_path,
  new_smart_tag_templates_liquid_instruction_path,
  new_smart_tag_templates_segmentation_instruction_path,
  new_smart_tag_templates_snippet_instruction_path,
} from '../routes';
import csrfHeaders from '../utilities/csrfHeaders';
import consumer from '../channels/consumer';
import { reloadElement } from '../../components/utils/add_element';
import {
  customizeEditorToolbar,
  defaultSetup,
  initializeAutoFormatting,
  initializeContextToolbar,
  initializeSlashCommands,
  setupCutListener,
  pastePreprocessBuilder,
  registerCustomIcons,
} from '../utilities/tinymce_utils';

TimeAgo.addDefaultLocale(en);

export default class extends BaseController {
  static targets = ['form', 'text_area', 'lastSaved', 'combination', 'formWrapper'];

  static outlets = ['campaigns--editor--header-controls--save-notice', 'campaigns--editor--header-controls'];

  static values = {
    readOnly: Boolean,
    campaignId: Number,
    lastUpdated: String,
  };

  get isDirty() {
    if (!tinymce.activeEditor) {
      return false;
    }

    const activeContent = tinymce.activeEditor.getContent();

    if (typeof this.savedValue === 'undefined') {
      // we're still loading tinymce...
      return false;
    }

    // TODO - we might need to bring back a library like cheerio to clean up the content
    const cleanContent = (content) => content;

    const cleanedContent = cleanContent(activeContent);
    return cleanedContent !== cleanContent(this.savedValue);
  }

  connect() {
    this.setup('controller.connect');
    clearTurboFrame('segmentation-page');
    this.lastAutoSaveAttempt = new Date();

    this.updateLastSavedInterval = setInterval(() => {
      this.lastUpdatedValueChanged();
    }, 5000);

    document.addEventListener('turbo:load', (event) => {
      if (event.detail.url.match(/campaigns\/\d+\/edit/)) {
        this.setup('turbo:load');
      }
    });
    this.combinationTargets.forEach((target) => {
      const os = navigator.userAgent;
      target.textContent = os.includes('Mac') ? 'Cmd + Option +' : 'Ctrl + Alt +';
    });
    this.setupLockPolling();
    this.setupSmartTagTemplateUpdates();
  }

  lastUpdatedValueChanged() {
    const timeAgo = new TimeAgo('en-US');
    const lastUpdated = new Date(this.lastUpdatedValue);
    this.campaignsEditorHeaderControlsSaveNoticeOutlet.updateTimeAgo(timeAgo.format(lastUpdated));
  }

  disconnect() {
    clearInterval(this.updateLastSavedInterval);
    this.tinymceDisconnect();
    this.clearLockPolling();
    this.clearSmartTagTemplateUpdates();
  }

  shortcut(e) {
    const shortcuts = {
      '†': 'snippet',
      t: 'snippet',
      å: 'liquid',
      a: 'liquid',
      ß: 'segmentation',
      s: 'segmentation',
    };
    if (!e.key) return;

    const shortcutKey = e.key.toLowerCase();
    const modals = document.querySelectorAll('.modal');
    const backdropModals = document.querySelectorAll('.backdrop-modal');
    const activeModal = [...modals].filter((modal) => !modal.classList.contains('hidden'));
    const activeBackdropModal = [...backdropModals].filter((modal) => !modal.classList.contains('hidden'));
    if (shortcutKey in shortcuts && (e.metaKey || e.ctrlKey) && e.altKey) {
      e.preventDefault();
      if (activeModal.length >= 1) {
        activeModal[0].classList.add('hidden');
        activeBackdropModal[0].classList.add('hidden');
        return;
      }
      const action = shortcuts[shortcutKey];
      this.linkClick(action);
    } else if (shortcutKey === 'enter' && activeModal.length >= 1) {
      this.modalKeydown(e, activeModal);
    }
  }

  modalKeydown(e, activeModal) {
    const modalMethods = {
      'segmentation-modal-body': 'segmentationKeydown',
      'summary-modal-body': 'summaryKeydown',
    };
    if (activeModal[0].id in modalMethods) {
      this[modalMethods[activeModal[0].id]](e);
    }
  }

  segmentationKeydown(e) {
    e.preventDefault();
    const submit = document.getElementById('generate-audiences')
      ? document.getElementById('generate-audiences')
      : document.getElementById('save-audiences');
    submit.click();
  }

  summaryKeydown(e) {
    // When editing summaries as require shift + enter to submit the form automatically.
    // This should maybe the the same across across all forms? @myxoh
    if (!e.shiftKey) {
      return false;
    }
    e.preventDefault();
    let submit = document.getElementById('create-summary');
    if (!submit) {
      submit = document.getElementById('fine-tune');
    }
    return submit.click();
  }

  ensureAutoSave() {
    try {
      this.autoSave(true)
        .catch((res) => res.text())
        .then((html) => {
          const threshold = 3 * 60 * 1000; // 3 minutes
          if (new Date() - new Date(this.lastUpdatedValue) > threshold) {
            Turbo.renderStreamMessage(html);
            this.campaignsEditorHeaderControlsSaveNoticeOutlet.setSavingStatus(false);
          }
        });
    } catch (error) {
      errorHandler(error, 'Error while autosaving campaign', {
        campaignId: this.campaignIdValue,
      });
    }
  }

  async beforeLeave(event) {
    if (this.isDirty) {
      if (!window.confirm('Leave site? Changes you made may not be saved.')) {
        event.preventDefault();
        if (event.type === 'beforeunload') {
          event.returnValue = '';
        }
      } else {
        this.tinymceDisconnect();
      }
    } else {
      this.tinymceDisconnect();
    }
  }

  tinymceDisconnect() {
    try {
      tinymce.remove();
    } catch {
      logger.debug('Error removing TinyMCE, perhaps not present?');
    }
  }

  // TODO(ariel): cleanup the multiple save methods and make it more consistent
  async autoSave(force = false) {
    if (!force && new Date() - this.lastAutoSaveAttempt < 5000) {
      return;
    }

    this.lastAutoSaveAttempt = new Date();
    if (!this.isDirty || tinymce.activeEditor?.mode.get() !== 'design') {
      return;
    }

    const campaignId = this.campaignIdValue;
    const currentTabId = window.Singulate.current_tab_id;
    const editorContent = tinymce.activeEditor.getContent();
    await fetch(campaign_path(campaignId), {
      method: 'PATCH',
      headers: {
        Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
        'Content-Type': 'application/json',
        ...csrfHeaders(),
      },
      body: JSON.stringify({ current_tab_id: currentTabId, campaign: { html: editorContent } }),
    }).then((res) => {
      if (res.status !== 200) throw res;
    });

    this.savedValue = editorContent;
    this.lastUpdatedValue = new Date().toISOString();
    this.campaignsEditorHeaderControlsSaveNoticeOutlet.setSavingStatus(true);
  }

  setup() {
    const self = this;

    // If the editor is already set up there's no point in initing again, we return early.
    if (tinymce.activeEditor) {
      // This is added to manually show the editor in the case where navigating with browser buttons has resulted in the
      // editor being hidden.
      if (tinymce.activeEditor.isHidden()) {
        tinymce.activeEditor.show();
      }
      return;
    }

    this.ensureAutoSave = debounce(this.ensureAutoSave, 300, { leading: true, trailing: false }).bind(this);

    tinymce.init({
      ...defaultSetup,
      toolbar: ['wow-tag branched-paragraph singulated-snippet | formatting responsiveImage | code | kebab'],
      contextmenu: 'link responsiveImage ctaContextMenu sectionContextMenu',

      height: 'calc(100vh - 64px)',
      forced_root_block: 'p',
      custom_elements: '~i',
      paste_preprocess: pastePreprocessBuilder(tinymce),
      setup: (editor) => {
        setupCutListener(editor);
        registerCustomIcons(editor);
        customizeEditorToolbar(editor, self);
        initializeSlashCommands(editor, self);
        initializeContextToolbar(editor);
        initializeAutoFormatting(editor);

        editor.addShortcut('meta+alt+a', 'Opens WoW Tag modal', () => {
          this.linkClick('liquid');
        });
        editor.addShortcut('meta+alt+s', 'Opens Segmentation modal', () => {
          this.linkClick('segmentation');
        });
        editor.addShortcut('meta+alt+t', 'Opens Snippet modal', () => {
          this.linkClick('snippet');
        });
        editor.on('change NodeChange Dirty', () => {
          self.autoSave();
        });
        editor.on('keydown', this.ensureAutoSave);
        editor.mode.set(this.readOnlyValue === true ? 'readonly' : 'design');
        editor.on('init', () => {
          // Enables stimulus inside the editor:
          const iframeWindow = editor.iframeElement.contentWindow;
          const iframeDocument = iframeWindow.document;
          const application = Application.start(iframeDocument.body);
          application.handleError = errorHandler;
          iframeWindow.Stimulus = application;
          register(application);

          this.savedValue = editor.getContent();
        });
      },
    });
  }

  deleteSmartTagTemplate(e) {
    const { id } = e.detail;
    // Window confirm:
    if (window.confirm('Are you sure you want to delete this smart tag?')) {
      // Finds element with id:
      // TODO - replace code with non turbo-tags.
      const matchingElements = tinymce.activeEditor.dom.select(
        `.smart-tag-parent[${window.Singulate.smart_tag_template_id_attribute}="${id}"]`,
      );
      // NOTE(jkogara): For some reason there are 2 elements by default, assuming this is how tiny tracks things?
      if (matchingElements.length === 2) {
        matchingElements.forEach((element) => {
          // Removes the element from the editor
          tinymce.activeEditor.dom.remove(element);
        });
      } else {
        matchingElements.slice(1).forEach((element) => {
          element.remove();
        });
      }
      this.autoSave(true);
    }
  }

  submit() {
    // Prevents autosave attempt when we're about to save anyway, fixes issue where smart tag templates are frozen as
    // "Generating...."
    this.lastAutoSaveAttempt = new Date();
    const form = this.formTarget;
    form.requestSubmit();
  }

  startSaving() {
    this.isSaving = true;
  }

  stopSaving() {
    this.isSaving = false;
  }

  linkClick(modalType) {
    const linkMappings = {
      liquid: new_smart_tag_templates_liquid_instruction_path({ campaign_id: this.campaignIdValue }),
      segmentation: new_smart_tag_templates_segmentation_instruction_path({ campaign_id: this.campaignIdValue }),
      snippet: new_smart_tag_templates_snippet_instruction_path({ campaign_id: this.campaignIdValue }),
    };

    Turbo.visit(linkMappings[modalType], { frame: 'modal' });
  }

  setupSmartTagTemplateUpdates() {
    this.smartTagTemplateUpdateSubscription = consumer.subscriptions.create(
      {
        channel: 'SmartTagTemplateUpdateChannel',
        campaign_id: this.campaignIdValue,
      },
      {
        connected() {
          logger.debug('Connected to SmartTagTemplateUpdateChannel');
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          reloadElement(data.smart_tag_template.id);
        },
      },
    );
  }

  clearSmartTagTemplateUpdates() {
    this.smartTagTemplateUpdateSubscription.unsubscribe();
  }

  setupLockPolling() {
    const currentTabId = window.Singulate.current_tab_id;
    const currentUserId = window.Singulate.user_id;
    this.lockPollingSubscription = consumer.subscriptions.create(
      { channel: 'CampaignChannel', campaign_id: `${this.campaignIdValue}`, current_tab_id: currentTabId },
      {
        received: (campaign) => {
          if (campaign.changed === 'locked_by') {
            let lockedByAnotherUser = false;
            let lockedByYouOnAnotherTab = false;
            if (campaign.locked_by_user_id) {
              if (campaign.locked_by_user_id !== currentUserId) {
                lockedByAnotherUser = true;
              } else if (campaign.locked_by_tab_id) {
                lockedByYouOnAnotherTab = campaign.locked_by_tab_id !== currentTabId;
              }
            }
            const lockedForMe = lockedByAnotherUser || lockedByYouOnAnotherTab;
            this.campaignsEditorHeaderControlsOutlet.toggleLocked(lockedForMe);
            this.campaignsEditorHeaderControlsOutlet.updateLockedBy(campaign.locked_by_user_name);
            tinymce.activeEditor.mode.set(lockedForMe ? 'readonly' : 'design');
            if (lockedForMe && tinymce.activeEditor.isDirty()) {
              tinymce.activeEditor.resetContent();
            }
          }

          if (campaign.changed === 'html') {
            if (!this.isSaving && !tinymce.activeEditor.isDirty()) {
              tinymce.activeEditor.setContent(campaign.html);
            }
            if (campaign.disabled === true) {
              tinymce.activeEditor.mode.set('readonly');

              const iframeDocument = tinymce.activeEditor.iframeElement.contentDocument;
              iframeDocument.querySelectorAll('span.delete-smart-tag').forEach((span) => span.remove());
              iframeDocument.addEventListener(
                'click',
                (e) => {
                  e.stopImmediatePropagation();
                  e.preventDefault();
                },
                true,
              );
            }
          }
        },
      },
    );

    // we receive RT updates, but let's poll in case the user that held the lock
    // stopped making changes but didn't close the tab or navigated away, and
    // the lock finally expired.
    const TEN_SECONDS = 10000;
    this.lockPollingInterval = setInterval(() => {
      this.lockPollingSubscription.perform('poll_lock_status');
    }, TEN_SECONDS);
  }

  clearLockPolling() {
    this.lockPollingSubscription.unsubscribe();
    clearInterval(this.lockPollingInterval);
  }
}
