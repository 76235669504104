import * as Sentry from '@sentry/browser';
import { logger } from './logger';

if (!Sentry.getClient()) {
  try {
    Sentry.init({
      dsn: window.Singulate?.sentry_dsn ?? '',
      release: window.Singulate?.release_sha,
      environment: window.Singulate?.environment ?? 'development',
      integrations: [
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: true,
          unblock: ['.sui-icon > *'],
        }),
      ],
      // Session Replay
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: window.Singulate?.sentry_replay_error_sample_rate ?? 0,
    });
    Sentry.setTag('exception_source', 'frontend');
    Sentry.setUser({ id: window.Singulate?.user_id });
  } catch (error) {
    logger.error(`Error configuring Sentry ${error}`);
  }
}

function errorHandler(error, message, detail) {
  if (window.Singulate?.environment !== 'production') {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  logger.error({ error, message, detail });
  // logger.error(error);
  Sentry.captureException(error, { message, detail });
}

export { Sentry, errorHandler };
