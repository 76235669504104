import { Controller } from '@hotwired/stimulus';
import { featureFlagEnabled } from '@root/utilities/utils';
import { Turbo } from '@hotwired/turbo-rails';
import { toast } from '@root/controllers/toast_controller';

export default class extends Controller {
  static outlets = ['campaign-editor', 'tiptap-editor'];

  async saveToGenerate() {
    try {
      if (this.hasTiptapEditorOutlet && featureFlagEnabled('rich_html_editor')) {
        this.tiptapEditorOutlet.clearLockPolling();
      } else if (this.hasCampaignEditorOutlet) {
        this.campaignEditorOutlet.clearLockPolling();
        await this.campaignEditorOutlet.autoSave(true);
      }
    } catch (error) {
      if (error instanceof Response && error.status === 422) {
        const html = await error.text();
        Turbo.renderStreamMessage(html);
        return;
      }

      toast({ message: 'Failed to save campaign. Please try again later.', type: 'error' });
      throw error;
    }
  }
}
