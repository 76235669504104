<script>
  export let editor;
  export let textColor;

  const swatches = [
    '#BFEDD2',
    '#FBEEB8',
    '#F8CAC6',
    '#ECCAFA',
    '#C2E0F4',
    '#2DC26B',
    '#F1C40F',
    '#E03E2D',
    '#B96AD9',
    '#3598DB',
    '#169179',
    '#E67E23',
    '#BA372A',
    '#843FA1',
    '#236FA1',
    '#ECF0F1',
    '#CED4D9',
    '#95A5A6',
    '#7E8C8D',
    '#34495E',
  ];
</script>

<div id="colorPicker">
  <div class="grid grid-cols-5 gap-1">
    {#each swatches as swatch}
    <button
      type="button"
      class="w-8 h-8 rounded-full border border-gray-200"
      style="background-color: {swatch}"
      on:click={() => {
        editor.chain().focus().setColor(swatch).run();
        textColor = swatch;
      }}
    />
    {/each}
  </div>
  <div class="flex flex-row gap-1">
    <button
      type="button"
      class="w-8 h-8 rounded-full border border-gray-200"
      style="background-color: #FFF"
      on:click={() => {
        editor.chain().focus().setColor("#FFFFFF").run();
      }}
    /> <!-- White -->
    <button
      type="button"
      class="w-8 h-8 rounded-full border border-gray-200"
      style="background-color: #000"
      on:click={() => {
        editor.chain().focus().setColor("#000000").run();
      }}
    /> <!-- Black -->
    <button
      type="button"
      class="w-8 h-8 rounded-full border border-gray-200"
      style="background-color: {textColor}"
      on:click={() => {
        editor.chain().focus().setColor(textColor).run();
      }}
    /> <!-- Current color -->
    <input
      type="color"
      bind:value={textColor}
      on:input={() => {
        editor.chain().focus().setColor(textColor).run();
      }}
    />
  </div>
</div>
