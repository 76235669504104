import DataIcon from '@components/sui/icon/svgs/DataIcon.svelte';
import CopyIcon from '@components/sui/icon/svgs/CopyIcon.svelte';
import LayoutAltOneIcon from '@components/sui/icon/svgs/LayoutAltOneIcon.svelte';
import DotsVerticalIcon from '@components/sui/icon/svgs/DotsVerticalIcon.svelte';
import Dropdown from '@components/sui/table/formatters/baseline/dropdown/dropdown.svelte';
import {
  results_campaign_path,
  start_duplicate_campaign_path,
  start_save_as_template_campaign_path,
} from '@root/routes';
import { featureFlagEnabled } from '@root/utilities/utils';

/**
 * Campaign kebab menu for Drafts
 */
function campaignsSentActionsCell(cell) {
  const div = document.createElement('div');

  // Get the cell element and make it show the overflow to display the kebab menu
  cell.getElement().style.overflow = 'visible';
  const table = cell.getTable();
  const rowIndex = cell.getRow().getPosition();
  const totalRows = table.getRows().length;
  const aboveContent = totalRows <= 3 ? false : rowIndex >= totalRows - 1;
  const items = [
    {
      label: 'Analytics',
      icon: DataIcon,
      url: results_campaign_path(cell.getData().id),
    },
    {
      label: 'Duplicate',
      icon: CopyIcon,
      url: start_duplicate_campaign_path(cell.getData().id),
      frame: 'modal',
    },
  ];
  if (featureFlagEnabled('templates_v2')) {
    items.push({
      label: 'Save as template',
      icon: LayoutAltOneIcon,
      url: start_save_as_template_campaign_path(cell.getData().id),
      frame: 'modal',
    });
  }

  new Dropdown({
    target: div,
    props: {
      DropdownIcon: DotsVerticalIcon,
      aboveContent,
      Items: items,
    },
  });

  return div;
}

export default { campaignsSentActionsCell };
