<script>
  import { onMount, onDestroy } from 'svelte';
  import { Editor } from '@tiptap/core';
  import StarterKit from '@tiptap/starter-kit';
  import Link from '@tiptap/extension-link';
  import Underline from '@tiptap/extension-underline';
  import Placeholder from '@tiptap/extension-placeholder';
  import BubbleMenu from '@tiptap/extension-bubble-menu';
  import { Color } from '@tiptap/extension-color';
  import TextStyle from '@tiptap/extension-text-style';
  import TextFormatting from '@components/tiptap_menu/TextFormatting.svelte';
  import SmartTagBlock from '@components/tiptap_editor/nodes/smart-tag-block';
  import SmartTagInline from '@components/tiptap_editor/nodes/smart-tag-inline';

  export let content;
  export let setup;

  let editor;
  let element;
  let textFormattingMenu;

  onMount(() => {
    editor = new Editor({
      element,
      content,
      onTransaction: () => {
        // force re-render so `editor.isActive` works as expected
        editor = editor;
      },
      extensions: [
        StarterKit,
        Link.configure({
          openOnClick: false,
          defaultProtocol: 'https',
        }),
        Underline,
        SmartTagBlock,
        SmartTagInline,
        Placeholder.configure({
          placeholder: 'Type "/" for a menu',
        }),
        BubbleMenu.configure({
          element: textFormattingMenu,
          tippyOptions: {
            theme: 'tiptap',
            arrow: false,
            appendTo: document.body,
          }
        }),
        Color,
        TextStyle,
      ],
      editorProps: {
        attributes: {
          class: 'focus:outline-none',
        },
      },
      autofocus: true,
    });

    setup(editor);
  });

  onDestroy(() => {
    if (editor) {
      editor.destroy();
    }
  });
</script>

<TextFormatting bind:textFormattingMenu={textFormattingMenu} {editor} />
<div bind:this={element} />
