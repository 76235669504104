import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['templateInput', 'template'];

  connect() {
    const defaultTemplate = this.templateTargets[0];

    if (this.hasTemplateInputTarget) {
      this.selectTemplate({ params: { value: '' }, currentTarget: defaultTemplate });
    }
  }

  selectTemplate(event) {
    const { value } = event.params;
    this.templateInputTarget.value = value;
    const templates = this.templateTargets;
    templates.forEach((template) => {
      template.classList.remove('selected-template');
    });
    const template = event.currentTarget;
    template.classList.add('selected-template');
  }
}
