import { mergeAttributes, Mark, Extension } from '@tiptap/core';

const SmartTagInlineWrapper = Mark.create({
  name: 'smartTagInlineWrapper',

  atom: true,

  contenteditable: false,

  addAttributes() {
    return {
      class: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.smart-tag-parent',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes({ contenteditable: 'false' }, HTMLAttributes), 0];
  },
});

const SmartTagCommon = Mark.create({
  name: 'smartTagCommon',

  atom: true,

  contenteditable: false,

  addAttributes() {
    return {
      class: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.smart-tag-template-common',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes({ contenteditable: 'false' }, HTMLAttributes), 0];
  },
});

const SmartTagDelete = Mark.create({
  name: 'smartTagDelete',

  atom: true,

  contenteditable: false,

  addAttributes() {
    return {
      class: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.delete-smart-tag',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes({ contenteditable: 'false' }, HTMLAttributes), 0];
  },
});

const SmartTagFa = Mark.create({
  name: 'smartTagFa',

  atom: true,

  contenteditable: false,

  addAttributes() {
    return {
      class: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.fa',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes({ contenteditable: 'false' }, HTMLAttributes), 0];
  },
});

const SmartTagLiquid = Mark.create({
  name: 'smartTagLiquid',
  contenteditable: false,

  addAttributes() {
    return {
      class: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.liquid',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes({ contenteditable: 'false' }, HTMLAttributes), 0];
  },
});

export default Extension.create({
  addExtensions() {
    return [SmartTagInlineWrapper, SmartTagCommon, SmartTagDelete, SmartTagFa, SmartTagLiquid];
  },
});
