import { mergeAttributes, Node } from '@tiptap/core';

export default Node.create({
  name: 'smartTagBlock',

  group: 'block',

  content: 'block*',

  atom: true,

  contenteditable: false,

  addAttributes() {
    return {
      class: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes({ contenteditable: 'false' }, HTMLAttributes), 0];
  },
});
