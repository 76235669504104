import { logger } from '@root/utilities/logger';
import { Turbo } from '@hotwired/turbo-rails';
import TimeAgo from 'javascript-time-ago';
import { clearTurboFrame } from '@root/utilities/utils';
import BaseController from '@root/controllers/base_controller';
import { errorHandler } from '@root/utilities/error_tracking';
import {
  campaign_path,
  new_smart_tag_templates_liquid_instruction_path,
  new_smart_tag_templates_segmentation_instruction_path,
  new_smart_tag_templates_snippet_instruction_path,
} from '@root/routes';
import csrfHeaders from '@root/utilities/csrfHeaders';
import consumer from '@root/channels/consumer';
import { reloadElement } from '@components/utils/add_element';
import Editor from './Editor.svelte';

export default class extends BaseController {
  static targets = [
    'form',
    'text_area',
    'lastSaved',
    'combination',
    'formWrapper',
    'stylesManager',
    'campaignEditor',
    'htmlContent',
    'editor',
  ];

  static outlets = ['campaigns--editor--header-controls--save-notice', 'campaigns--editor--header-controls'];

  static values = {
    readOnly: Boolean,
    campaignId: Number,
    lastUpdated: String,
  };

  connect() {
    this.receiveSmartTag = null;
    this.setup('controller.connect');
    clearTurboFrame('segmentation-page');
    this.lastAutoSaveAttempt = new Date();

    this.updateLastSavedInterval = setInterval(() => {
      this.lastUpdatedValueChanged();
    }, 5000);

    document.addEventListener('turbo:load', (event) => {
      if (event.detail.url.match(/campaigns\/\d+\/edit/)) {
        this.setup('turbo:load');
      }
    });
    this.combinationTargets.forEach((target) => {
      const os = navigator.userAgent;
      target.textContent = os.includes('Mac') ? 'Cmd + Option +' : 'Ctrl + Alt +';
    });
    this.setupLockPolling();
    this.setupSmartTagTemplateUpdates();
  }

  lastUpdatedValueChanged() {
    const timeAgo = new TimeAgo('en-US');
    const lastUpdated = new Date(this.lastUpdatedValue);
    if (this.hasCampaignsEditorHeaderControlsSaveNoticeOutlet) {
      this.campaignsEditorHeaderControlsSaveNoticeOutlet.updateTimeAgo(timeAgo.format(lastUpdated));
    }
  }

  disconnect() {
    clearInterval(this.updateLastSavedInterval);
    this.clearLockPolling();
    this.clearSmartTagTemplateUpdates();
  }

  shortcut(e) {
    const shortcuts = {
      '†': 'summary',
      t: 'summary',
      å: 'liquid',
      a: 'liquid',
      ß: 'segmentation',
      s: 'segmentation',
    };
    const shortcutKey = e.key.toLowerCase();
    const modals = document.querySelectorAll('.modal');
    const backdropModals = document.querySelectorAll('.backdrop-modal');
    const activeModal = [...modals].filter((modal) => !modal.classList.contains('hidden'));
    const activeBackdropModal = [...backdropModals].filter((modal) => !modal.classList.contains('hidden'));
    if (shortcutKey in shortcuts && (e.metaKey || e.ctrlKey) && e.altKey) {
      e.preventDefault();
      if (activeModal.length >= 1) {
        activeModal[0].classList.add('hidden');
        activeBackdropModal[0].classList.add('hidden');
        return;
      }
      const action = shortcuts[shortcutKey];
      this.linkClick(action);
    } else if (shortcutKey === 'enter') {
      this.modalKeydown(e, activeModal);
    }
  }

  modalKeydown(e, activeModal) {
    const modalMethods = {
      'segmentation-modal-body': 'segmentationKeydown',
      'summary-modal-body': 'summaryKeydown',
    };
    if (activeModal[0].id in modalMethods) {
      this[modalMethods[activeModal[0].id]](e);
    }
  }

  segmentationKeydown(e) {
    e.preventDefault();
    const submit = document.getElementById('generate-audiences')
      ? document.getElementById('generate-audiences')
      : document.getElementById('save-audiences');
    submit.click();
  }

  summaryKeydown(e) {
    // When editing summaries as require shift + enter to submit the form automatically.
    // This should maybe the the same across across all forms? @myxoh
    if (!e.shiftKey) {
      return false;
    }
    e.preventDefault();
    let submit = document.getElementById('create-summary');
    if (!submit) {
      submit = document.getElementById('fine-tune');
    }
    return submit.click();
  }

  ensureAutoSave() {
    try {
      this.autoSave(true);
    } catch (error) {
      errorHandler(error, 'Error while autosaving campaign', {
        campaignId: this.campaignIdValue,
      });
    }
  }

  async beforeLeave(event) {
    if (this.isDirty) {
      if (!window.confirm('Leave site? Changes you made may not be saved.')) {
        event.preventDefault();
        if (event.type === 'beforeunload') {
          event.returnValue = '';
        }
      }
    }
  }

  async setup() {
    const self = this;

    if (this.editor) {
      return;
    }

    await new Promise((resolve) => {
      new Editor({
        target: this.editorTarget,
        props: {
          content: this.htmlContentTarget.value,
          setup: resolve,
        },
      });
    }).then((editor) => {
      self.editor = editor;
    });
  }

  createElementFromHTML(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    return div.firstChild;
  }

  deleteSmartTagTemplate(e) {
    const { id } = e.detail;
    // Window confirm:
    if (window.confirm('Are you sure you want to delete this smart tag?')) {
      // Finds element with id:
      // TODO - replace code with non turbo-tags.
      const dom = document;
      dom
        .querySelectorAll(`.smart-tag-parent[${window.Singulate.smart_tag_template_id_attribute}="${id}"]`)
        .forEach((element) => {
          // Removes the element from the editor
          element.remove();
        });
    }
  }

  submit() {
    // Prevents autosave attempt when we're about to save anyway, fixes issue where smart tag templates are frozen as
    // "Generating...."
    const html = this.editor.getHTML();
    this.save(html);
  }

  save(html, json) {
    return new Promise((resolve, reject) => {
      const campaignId = this.campaignIdValue;
      const currentTabId = window.Singulate.current_tab_id;
      fetch(campaign_path(campaignId), {
        method: 'PATCH',
        headers: {
          Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
          'Content-Type': 'application/json',
          ...csrfHeaders(),
        },
        body: JSON.stringify({ current_tab_id: currentTabId, campaign: { html, editor_data: json } }),
      })
        .then((res) => {
          if (res.status === 200) {
            this.savedValue = html;
            this.lastUpdatedValue = new Date().toISOString();
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((error) => reject(error));
    });
  }

  startSaving() {
    this.isSaving = true;
  }

  stopSaving() {
    this.isSaving = false;
  }

  linkClick(modalType) {
    const linkMappings = {
      liquid: new_smart_tag_templates_liquid_instruction_path({ campaign_id: this.campaignIdValue }),
      segmentation: new_smart_tag_templates_segmentation_instruction_path({ campaign_id: this.campaignIdValue }),
      snippet: new_smart_tag_templates_snippet_instruction_path({ campaign_id: this.campaignIdValue }),
    };

    Turbo.visit(linkMappings[modalType], { frame: 'modal' });
  }

  setupSmartTagTemplateUpdates() {
    const self = this;
    this.smartTagTemplateUpdateSubscription = consumer.subscriptions.create(
      {
        channel: 'SmartTagTemplateUpdateChannel',
        campaign_id: this.campaignIdValue,
      },
      {
        connected() {
          logger.debug('Connected to SmartTagTemplateUpdateChannel');
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          reloadElement(data.smart_tag_template.id, self.editor.Canvas.getFrameEl());
        },
      },
    );
  }

  clearSmartTagTemplateUpdates() {
    this.smartTagTemplateUpdateSubscription.unsubscribe();
  }

  setupLockPolling() {
    const currentTabId = window.Singulate.current_tab_id;
    const currentUserId = window.Singulate.user_id;
    this.lockPollingSubscription = consumer.subscriptions.create(
      { channel: 'CampaignChannel', campaign_id: `${this.campaignIdValue}`, current_tab_id: currentTabId },
      {
        received: (campaign) => {
          if (campaign.changed === 'locked_by') {
            let lockedByAnotherUser = false;
            let lockedByYouOnAnotherTab = false;
            if (campaign.locked_by_user_id) {
              if (campaign.locked_by_user_id !== currentUserId) {
                lockedByAnotherUser = true;
              } else if (campaign.locked_by_tab_id) {
                lockedByYouOnAnotherTab = campaign.locked_by_tab_id !== currentTabId;
              }
            }
            const lockedForMe = lockedByAnotherUser || lockedByYouOnAnotherTab;
            this.campaignsEditorHeaderControlsOutlet.toggleLocked(lockedForMe);
            this.campaignsEditorHeaderControlsOutlet.updateLockedBy(campaign.locked_by_user_name);
            try {
              const componentWrappers = this.editor.DomComponents.getWrapper();
              if (lockedForMe) {
                this.campaignEditorTarget.addEventListener('dragstart', this.disablingEvents, true);
                this.campaignEditorTarget.addEventListener('click', this.disablingEvents, true);
                this.stylesManagerTarget.classList.add('hidden');
                componentWrappers.onAll((comp) => comp.set({ editable: false, draggable: false }));
              } else {
                this.campaignEditorTarget.removeEventListener('dragstart', this.disablingEvents, true);
                this.campaignEditorTarget.removeEventListener('click', this.disablingEvents, true);
                this.stylesManagerTarget.classList.remove('hidden');
                componentWrappers.onAll((comp) => comp.set({ editable: true, draggable: true }));
              }
            } catch (error) {
              logger.error('Error dealing with lock behavior', error);
            }
          }

          if (campaign.changed === 'html') {
            // if (!this.isSaving && !tinymce.activeEditor.isDirty()) {
            //   tinymce.activeEditor.setContent(campaign.html);
            // }
            if (campaign.disabled === true) {
              // tinymce.activeEditor.mode.set('readonly');
              // const iframeDocument = tinymce.activeEditor.iframeElement.contentDocument;
              // iframeDocument.querySelectorAll('span.delete-smart-tag').forEach((span) => span.remove());
              // iframeDocument.addEventListener(
              //   'click',
              //   (e) => {
              //     e.stopImmediatePropagation();
              //     e.preventDefault();
              //   },
              //   true,
              // );
            }
          }
        },
      },
    );

    // we receive RT updates, but let's poll in case the user that held the lock
    // stopped making changes but didn't close the tab or navigated away, and
    // the lock finally expired.
    const TEN_SECONDS = 10000;
    this.lockPollingInterval = setInterval(() => {
      this.lockPollingSubscription.perform('poll_lock_status');
    }, TEN_SECONDS);
  }

  disablingEvents(e) {
    e.stopImmediatePropagation();
    e.preventDefault();
  }

  clearLockPolling() {
    this.lockPollingSubscription.unsubscribe();
    clearInterval(this.lockPollingInterval);
  }
}
