import { Controller } from '@hotwired/stimulus';
import addElement from '@components/utils/add_element';
import { Liquid } from 'liquidjs';
import { featureFlagEnabled } from '@root/utilities/utils';
import Instruction from './Instruction.svelte';

export default class extends Controller {
  static targets = [
    'inputField',
    'showFallback',
    'fallbackEnabled',
    'blankFallback',
    'fallback',
    'instructions',
    'instructionsField',
  ];

  static outlets = ['dropdown-with-icon', 'tiptap-editor'];

  static values = {
    fallbackTextEnabled: Boolean,
    smartTagTemplateId: Number,
    addToEditorOnSave: Boolean,
    schemaAttributes: Object,
    contactAttribute: String,
    schemaSummary: Object,
  };

  connect() {
    if (this.hasShowFallbackTarget) {
      this.renderEditFallback(this.fallbackTextEnabledValue);
    }
    this.toggleFallback();
    this.updateSummary();
  }

  toggleInstructionsField() {
    this.instructionsFieldTarget.classList.toggle('hidden');
    this.instructionsTarget.classList.toggle('hidden');
  }

  updateSummary() {
    const attributeKey = `count_${this.contactAttributeValue}`;
    const attributeCount = parseInt(this.schemaSummaryValue[attributeKey] || 0, 10);
    const totalCount = parseInt(this.schemaSummaryValue.total || 0, 10);

    if (totalCount !== 0) {
      const percentage = Math.round((attributeCount / totalCount) * 100);
      this.dropdownWithIconOutlet.dropdownAdditionalInfoTarget.textContent = `${percentage}% of your contacts`;
    }
    const { friendly_name: friendlyName } = this.schemaAttributesValue[this.contactAttributeValue];
    // This is needed in this case because the default text labels
    // already contain the % - because we are adding those in the additional info section
    // We want to overwrite the text label with just the name of the attribute
    this.dropdownWithIconOutlet.selectedOptionTextTarget.textContent = friendlyName;
  }

  renderEditFallback(enabled) {
    if (enabled) {
      this.showFallbackTarget.classList.remove('hidden');
      this.blankFallbackTarget.classList.add('hidden');
      this.showFallbackTarget.required = true;
    } else {
      this.showFallbackTarget.classList.add('hidden');
      this.blankFallbackTarget.classList.remove('hidden');
      this.showFallbackTarget.required = false;
    }
  }

  isRichHtmlEditor() {
    return featureFlagEnabled('rich_html_editor');
  }

  submitForm() {
    if (this.addToEditorOnSaveValue) {
      addElement({
        json: {
          id: this.smartTagTemplateIdValue,
        },
        onlyRender: this.isRichHtmlEditor(),
      }).then((html) => {
        if (this.isRichHtmlEditor()) {
          this.tiptapEditorOutlet.receiveSmartTag(html);
        }
        // Sends custom event:
        // closeTurboModal
        window.dispatchEvent(new CustomEvent('closeTurboModal'));
      });
    }
  }

  toggleFallback() {
    this.fallbackTextEnabledValue = this.fallbackEnabledTarget.checked;
    this.renderEditFallback(this.fallbackTextEnabledValue);

    this.fallbackEdit();
    this.updateLiquidFunction();
  }

  setContactAttribute(event) {
    this.contactAttributeValue = event.currentTarget.dataset.value;

    this.updateLiquidFunction();
    this.updateSummary();
  }

  updateLiquidFunction() {
    if (document.activeElement === this.inputFieldTarget) {
      return;
    }

    const parsed = this.parseLiquid(this.inputFieldTarget.value);

    parsed.forEach((variable) => {
      if (variable.identifiers.length === 2 && variable.identifiers[0] === 'contact') {
        variable.identifiers[1] = this.contactAttributeValue;
        if (this.fallbackTextEnabledValue) {
          let fallbackFilter = variable.filters.find((filter) => filter.name === 'default');
          if (!fallbackFilter) {
            fallbackFilter = { name: 'default' };
            variable.filters.push(fallbackFilter);
          }
          fallbackFilter.args = [this.showFallbackTarget.value];
        } else {
          variable.filters = variable.filters.filter((filter) => filter.name !== 'default');
        }
      }
    });

    this.instructionsTarget.innerHTML = '';

    const liquid = parsed.map((variable) => {
      const identifiers = variable.identifiers.join('.');
      const filters = variable.filters.map((filter) => {
        if (filter.args.length > 0) {
          const args = filter.args.map((arg) => `"${arg}"`).join(', ');
          return [filter.name, args].join(': ');
        }
        return filter.name;
      });

      new Instruction({
        target: this.instructionsTarget,
        props: {
          identifier: identifiers,
          filters: variable.filters,
        },
      });
      return `{{ ${[identifiers, ...filters].join(' | ')} }}`;
    });

    this.inputFieldTarget.value = liquid.join(' ');
  }

  updateDropdownAndFallback() {
    const { value } = this.inputFieldTarget;
    const parsed = this.parseLiquid(value);

    this.updateDropdown(parsed);
    this.updateFallback(parsed);
  }

  updateDropdown(parsed) {
    const contactAttribute = this.extractContactAttributeFrom(parsed);
    const dropdown = this.dropdownWithIconOutlet.buttonTarget;

    if (contactAttribute) {
      const newValue = contactAttribute.identifiers[1];
      const keyObj =
        this.schemaAttributesValue[newValue] !== undefined
          ? { [newValue]: this.schemaAttributesValue[newValue] }
          : null;

      const option = this.dropdownWithIconOutlet.optionTargets.find((div) => div.dataset.value === newValue);

      if (keyObj) {
        this.enableDropdown(dropdown);
        option.click();
      } else {
        this.disableDropdown(dropdown);
      }
    } else {
      this.disableDropdown(dropdown);
    }
  }

  enableDropdown(dropdown) {
    dropdown.removeAttribute('disabled');
    dropdown.classList.remove('opacity-40', 'cursor-not-allowed');
  }

  disableDropdown(dropdown) {
    dropdown.setAttribute('disabled', 'true');
    dropdown.classList.add('opacity-40', 'cursor-not-allowed');
  }

  updateFallback(parsed) {
    const contactAttribute = this.extractContactAttributeFrom(parsed);

    if (contactAttribute) {
      const fallbackFilter = contactAttribute.filters.find((filter) => filter.name === 'default');

      if (fallbackFilter) {
        this.showFallbackTarget.value = fallbackFilter.args[0] || '';
        this.fallbackEnabledTarget.checked = true;
      } else {
        this.fallbackEnabledTarget.checked = false;
      }
      this.toggleFallback();
      this.fallbackEdit();
    }
  }

  updateFallbackText() {
    this.fallbackEdit();
    this.updateLiquidFunction();
  }

  fallbackEdit() {
    const inputField = this.showFallbackTarget;
    const checkbox = this.fallbackEnabledTarget;
    const fallbackItem = this.fallbackTarget;
    if (!checkbox.checked || inputField.value === '') {
      fallbackItem.classList.add('text-gray-400');
      fallbackItem.innerText = 'No fallback provided';
    } else {
      const template = this.liquidParse(this.inputFieldTarget.value);
      fallbackItem.classList.remove('text-gray-400');
      if (template) {
        this.liquidEngine.render(template, '').then((result) => {
          fallbackItem.innerText = result;
        });
      } else {
        fallbackItem.innerText = inputField.value;
      }
    }
  }

  get liquidEngine() {
    return new Liquid();
  }

  liquidParse(liquid) {
    let tpl;
    try {
      tpl = this.liquidEngine.parse(liquid);
      return tpl;
    } catch (e) {
      return null;
    }
  }

  parseLiquid(liquid) {
    try {
      const tpl = this.liquidParse(liquid);
      const liquidVariables = tpl.filter((token) => token.value);

      return liquidVariables.map((liquidVariable) => ({
        identifiers: liquidVariable.value.initial.postfix[0].props.map((identifier) => identifier.content),
        filters: liquidVariable.value.filters.map((filter) => ({
          name: filter.name,
          args: filter.args.map((arg) => arg.content),
        })),
      }));
    } catch (e) {
      return [];
    }
  }

  extractContactAttributeFrom(parsed) {
    try {
      if (parsed.length === 1 && parsed[0].identifiers.length === 2 && parsed[0].identifiers[0] === 'contact') {
        return parsed[0];
      }
      return null;
    } catch (e) {
      return null;
    }
  }
}
