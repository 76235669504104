import tinymce from 'tinymce/tinymce';
import BaseController from '@root/controllers/base_controller';
import 'tinymce/models/dom/model';
import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/code';
import {
  defaultSetup,
  customizeEditorToolbar,
  registerCustomIcons,
  pastePreprocessBuilder,
  setupCutListener,
} from '@root/utilities/tinymce_utils';
import register from '@root/editor_controllers/register';
import { Application } from '@hotwired/stimulus';
import { errorHandler } from '@root/utilities/error_tracking';

import { Turbo } from '@hotwired/turbo-rails';
import {
  new_smart_tag_templates_liquid_instruction_path,
  new_smart_tag_templates_segmentation_instruction_path,
  new_smart_tag_templates_snippet_instruction_path,
} from '@root/routes';

export default class extends BaseController {
  static targets = ['editor', 'form', 'continue', 'allowEmpty'];

  static values = { campaignId: Number };

  connect() {
    this.setup();
  }

  linkClick(modalType) {
    const linkMappings = {
      liquid: new_smart_tag_templates_liquid_instruction_path({ campaign_id: this.campaignIdValue }),
      segmentation: new_smart_tag_templates_segmentation_instruction_path({ campaign_id: this.campaignIdValue }),
      snippet: new_smart_tag_templates_snippet_instruction_path({ campaign_id: this.campaignIdValue }),
    };

    Turbo.visit(linkMappings[modalType], { frame: 'modal' });
  }

  setup() {
    const self = this;

    if (tinymce.activeEditor) {
      tinymce.activeEditor.remove();
    }

    tinymce.init({
      ...defaultSetup,
      toolbar: ['wow-tag singulated-snippet | formatting responsiveImage | code | kebab'],
      contextmenu: 'link image ctaContextMenu sectionContextMenu',
      height: '100%',
      paste_preprocess: pastePreprocessBuilder(tinymce, window.parent),
      setup: (editor) => {
        setupCutListener(editor);
        registerCustomIcons(editor);
        customizeEditorToolbar(editor, self, { unsubscribe: false, segmentation: false, liquid: true, snippet: true });
        editor.on('init', () => {
          const iframeWindow = editor.iframeElement.contentWindow;
          const iframeDocument = iframeWindow.document;
          const application = Application.start(iframeDocument.body);
          application.handleError = errorHandler;
          iframeWindow.Stimulus = application;
          register(application);
          this.enableOrDisableParagraph();
        });
      },
    });
  }

  enableOrDisableParagraph() {
    if (this.allowEmptyTarget.checked) {
      this.savedParagraph = tinymce.activeEditor.getContent();
      tinymce.activeEditor.setContent('');
      tinymce.activeEditor.mode.set('readonly');
    } else {
      tinymce.activeEditor.mode.set('design');
      if (this.savedParagraph) {
        tinymce.activeEditor.setContent(this.savedParagraph);
      }
    }
  }

  fillSubmitType() {
    // When submitting with Done button it fills the "submit_type" field to finish and close the flow
    const hiddenInput = this.formTarget.querySelector('input[name="submit_type"]');
    if (hiddenInput) {
      hiddenInput.value = 'done';
    }
  }

  deleteSmartTagTemplate(e) {
    const { id } = e.detail;
    // Window confirm:
    if (window.confirm('Are you sure you want to delete this smart tag?')) {
      // Finds element with id:
      // TODO - replace code with non turbo-tags.
      tinymce.activeEditor.dom
        .select(`.smart-tag-parent[${window.Singulate.smart_tag_template_id_attribute}="${id}"]`)
        .forEach((element) => {
          // Removes the element from the editor
          tinymce.activeEditor.dom.remove(element);
        });
      this.autoSave(true);
    }
  }

  toggleRules() {
    const arrowIcon = document.getElementById('arrow-down');
    const element = document.getElementById('rules-paragraph');
    if (element.classList.contains('text-nowrap')) {
      element.classList.remove('text-nowrap');
      arrowIcon.classList.replace('rotate-0', 'rotate-180');
    } else {
      element.classList.add('text-nowrap');
      arrowIcon.classList.replace('rotate-180', 'rotate-0');
    }
  }

  submitForm() {
    // When submitting over the form we do not want to redirect
    // NOTE(jkogara): Removed this as unsure WTF it does :smile:
    // this.continueTarget.value = '';
    this.formTarget.submit();
  }

  async submitFormAndRemoveEditor() {
    this.submitForm();

    if (tinymce.activeEditor) {
      tinymce.activeEditor.remove();
    }
  }
}
