import { Controller } from '@hotwired/stimulus';
import { featureFlagEnabled } from '@root/utilities/utils';

export default class extends Controller {
  static outlets = ['campaign-editor', 'tiptap-editor'];

  static targets = ['lastSaved'];

  save() {
    if (!featureFlagEnabled('rich_html_editor')) {
      this.campaignEditorOutlet.submit();
    }

    if (featureFlagEnabled('rich_html_editor')) {
      this.tiptapEditorOutlet.submit();
    }
  }

  updateTimeAgo(timeAgo) {
    this.lastSavedTarget.textContent = timeAgo;
  }

  setSavingStatus(saving = true) {
    if (saving) {
      this.lastSavedTarget.classList.remove('text-red-800', 'font-bold');
    } else {
      this.lastSavedTarget.classList.add('text-red-800', 'font-bold');
    }
  }
}
